import React from "react"
import styled from "styled-components"

import GemLogo from "src/images/gem.svg"

const A = styled.a`
  display: flex;
  align-items: center;
  color: #888888;
  font-size: 10px;
  font-weight: normal;
  font-style: italic;
  opacity: 1;
  transition: opacity 200ms;

  &:hover {
    opacity: 0.6;
  }
`

const Logo = styled(GemLogo)`
  width: 32px;
  height: 10px;
  margin: 0 4px;
`

const ByGemFooter: React.FC<{}> = () => {
  return (
    <A href="https://gem.app" target="_blank">
      by <Logo /> – Search All Online Vintage
    </A>
  )
}

export default ByGemFooter
