import React, { ReactElement } from "react"
import styled from "styled-components"
import { cssMobileOnly } from "src/styles/styleConstants"

interface Props {
  h?: number
  v?: number
  mobileH?: number
  mobileV?: number
}

export default function Space(props: Props): ReactElement {
  return <Div {...props} />
}

const Div = styled.div<Props>`
  ${({ h }) => (h ? `width: ${h}px;` : "")}
  ${({ v }) => (v ? `height: ${v}px;` : "")}

  ${cssMobileOnly} {
    ${({ mobileH }) => (mobileH ? `width: ${mobileH}px;` : "")}
    ${({ mobileV }) => (mobileV ? `height: ${mobileV}px;` : "")}
  }
`
