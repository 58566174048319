import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Space from "./Space"

interface IconLinkProps {
  linkText: string
  linkUrl?: string
  Icon?: React.ComponentType
  iconUrl?: string
  anchorElement?: boolean
  newTab?: boolean
}

export default function IconLink({
  linkText,
  linkUrl,
  Icon,
  iconUrl,
  anchorElement = false,
  newTab = true,
}: IconLinkProps) {
  // Automatically use anchor element for linkUrls starting with protocols
  const useA = anchorElement || /^(https?|mailto|tel):/i.test(linkUrl)

  const contents = (
    <Item>
      {iconUrl && <img src={iconUrl} />}
      {Icon && (
        <>
          <Icon width="28" height="28" style={{ flexShrink: 0 }} />
          <Space h={10} style={{ flexShrink: 0 }} />
        </>
      )}
      <div>{linkText}</div>
    </Item>
  )

  if (linkUrl) {
    if (useA) {
      return (
        <a
          href={linkUrl}
          target={newTab ? "_blank" : "_self"}
          rel={newTab ? "noopener" : ""}
        >
          {contents}
        </a>
      )
    } else {
      return <Link to={linkUrl}>{contents}</Link>
    }
  } else {
    return contents
  }
}

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  img {
    width: 20px;
    height: 20px;
  }

  div {
    font-size: 15px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
`
