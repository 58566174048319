import React from "react"
import styled from "styled-components"

import Tag from "./Tag"

type Props = {
  tags: string[]
}

export default function TagGroup({ tags = [] }: Props) {
  if (tags == null || tags.length === 0) {
    return null
  }
  const dedupTags = [...new Set(tags)]
  return (
    <ListUl>
      {dedupTags.map((tag) => (
        <Tag key={tag} tag={tag} />
      ))}
    </ListUl>
  )
}

const ListUl = styled.ul<{ small?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 5px 0;
  margin-left: -5px;
  padding: 0;
`
