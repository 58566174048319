import buildNYAddressString from "./buildNYAddressString"
import { Store } from "src/gatsby-node/data/dataSchemaV1"

const googleMapsBaseUrl = "https://www.google.com/maps/search/?api=1"

export default function googleSearchUrl(store: Store): null | string {
  if (!(store.address && store.name)) {
    return null
  }
  const addressString = buildNYAddressString(store.address)
  const url = `${googleMapsBaseUrl}&query=${encodeURIComponent(
    `${store.name}, ${addressString}`
  )}`
  return url
}
