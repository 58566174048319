import React from "react"
import styled from "styled-components"

import {
  cssComputerOnly,
  cssMobileOnly,
  computerContentWidth,
  overlayShadow,
  backgroundStyle,
  actionButtonHeight,
} from "../styles/styleConstants"
import { Link } from "gatsby"
import ByGemFooter from "./ByGemFooter"
import PageEndGradient from "./PageEndGradient"

const Footer = () => {
  return (
    <Background>
      <ShadowArea>
        <PageEndGradient />
      </ShadowArea>
      <Content>
        <Items>
          <FooterLink to="/about">About</FooterLink>
          <FooterLink to="/privacy">Privacy</FooterLink>
          <FooterLink to="/terms">Terms</FooterLink>
        </Items>
        <Items>
          <ByGemFooter />
        </Items>
      </Content>
    </Background>
  )
}

export default Footer

const Background = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 12px;
  ${backgroundStyle}

  ${cssComputerOnly} {
    position: fixed;
    left: ${computerContentWidth};
    bottom: 0;
    width: 375px;
    border-top-right-radius: 10px;
    z-index: 50;
    box-shadow: ${overlayShadow};
  }
`

const ShadowArea = styled.div`
  ${cssComputerOnly} {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  ${cssMobileOnly} {
    padding: 0 20px 35px;
  }

  ${cssComputerOnly} {
    padding: 0 14px;
    height: ${actionButtonHeight};
  }
`

const Items = styled.div`
  display: flex;
  align-items: center;

  ${cssMobileOnly} {
    height: 36px;
  }
`

const FooterLink = styled(Link)`
  margin-right: 12px;
  color: black;

  ${cssMobileOnly} {
    margin-right: 20px;
  }
`
