import React, { ReactElement } from "react"
import styled from "styled-components"

interface Props {
  children: ReactElement[]
}

export default function ActionButtonsArea({ children }: Props): ReactElement {
  return <ContainerDiv>{children}</ContainerDiv>
}

const ContainerDiv = styled.div`
  display: flex;
  margin: 20px 0;

  & > * {
    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }
`
