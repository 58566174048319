import React from "react"
import styled from "styled-components"

const H1 = styled.h1`
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
  color: black;
  letter-spacing: 0.01em;

  @media print {
    font-size: 20px;
  }
`

const Separator = styled.span`
  display: none;
`

const SubTitleSpan = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 500;
`

export interface PageTitleProps {
  title: string
  subTitle?: string
}

const PageTitle: React.FC<PageTitleProps> = ({ title, subTitle }) => {
  return (
    <H1>
      {title}
      {subTitle && (
        <>
          <Separator> • </Separator>
          <SubTitleSpan>{subTitle}</SubTitleSpan>
        </>
      )}
    </H1>
  )
}

export default PageTitle
