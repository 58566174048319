import React, { ReactElement } from "react"
import styled from "styled-components"

export default function PageEndGradient(): ReactElement {
  return <Shadow />
}

const Shadow = styled.div`
  display: block;
  position: relative;
  height: 26px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  margin-bottom: -10px;
`
