import React from "react"
import styled from "styled-components"

import HourSetItem from "./HourSetItem"
import { Hours } from "src/gatsby-node/data/dataSchemaV1"

interface HourSetProps {
  hours: Hours[]
  small?: boolean
}

const HoursTable = styled.table`
  font-size: 14px;
  border-spacing: 0px;
`

export default function HourSet({ hours, small }: HourSetProps) {
  return (
    <HoursTable>
      <tbody>
        {(hours ?? []).map((hourSet, index) => {
          return <HourSetItem key={index} small={small} hourSet={hourSet} />
        })}
      </tbody>
    </HoursTable>
  )
}

HourSet.defaultProps = {
  open: [],
  small: false,
}
