import React, { ReactElement } from "react"
import styled from "styled-components"

interface Props {}

export default function HorizontalRule({}: Props): ReactElement {
  return <Hr />
}

const Hr = styled.hr`
  border: 0;
  background: rgba(0, 0, 0, 0.05);
  height: 1px;
`
