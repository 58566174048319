import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"

export interface SEOProps {
  title?: string
  description?: string
  keywords?: string[]
  canonicalPath?: string
}

const SEO = ({ title, description, keywords, canonicalPath }: SEOProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            subTitle
            domainUrl
            description
          }
        }
      }
    `
  )
  const { siteMetadata } = data?.site

  const titleLong = title ?? `${siteMetadata.title} – ${siteMetadata.subTitle}`
  const titleShort = title ? title + " • " + siteMetadata.title : titleLong
  const descriptionText = description ?? siteMetadata.description
  const keywordsToUse =
    keywords && keywords.length > 0
      ? keywords
      : ["vintage clothing", "resale", "thrift", "clothing", "map"]

  const city = process.env.GATSBY_CITY
  const coverImageUrl = `https://${city}vintagemap.com/img/${city}-vintage-map-cover.png`
  const url = `${siteMetadata.domainUrl}${globalHistory.location.pathname}`
  const canonicalUrl = canonicalPath
    ? `${siteMetadata.domainUrl}${canonicalPath}`
    : url

  return (
    <Helmet>
      <html lang="en" />
      <title>{titleShort}</title>
      <meta name="description" content={descriptionText} />
      <meta property="og:title" content={titleLong} />
      <meta property="og:description" content={descriptionText} />
      <meta property="og:image" content={coverImageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={titleLong} />
      <meta name="twitter:description" content={descriptionText} />
      <meta name="keywords" content={keywordsToUse.join(", ")} />
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  )
}

export default SEO
