import React from "react"
import styled from "styled-components"

import * as hours from "../utils/hours"
import * as styleConstants from "../styles/styleConstants"

import { HourSet } from "../types"

const TableRow = styled.tr<{ small?: boolean }>`
  td {
    font-size: ${props =>
      props.small
        ? styleConstants.bodyTextPrintSize
        : styleConstants.bodyTextScreenSize};
    padding-right: 8px;
  }
`

interface HourSetItemProps {
  hourSet: HourSet
  small?: boolean
}

export default function HourSetItem(props: HourSetItemProps) {
  const { weekdays, timeRange, appointment } = hours.hourSetItemParts(
    props.hourSet,
    {
      ampm: true,
    }
  )
  const hourSetParts = [weekdays, timeRange, appointment]
  const slots = []
  hourSetParts.forEach(hourSetPart => {
    if (hourSetPart) {
      slots.push(hourSetPart)
    }
  })
  return (
    <TableRow small={props.small}>
      <td>{slots[0]}</td>
      <td>{slots[1]}</td>
      <td>{slots[2]}</td>
    </TableRow>
  )
}
