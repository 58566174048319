import React, { ReactElement } from "react"
import styled from "styled-components"
import * as email from "src/utils/email"
import isBrowser from "src/utils/isBrowser"

interface Props {
  storeName: string
}

export default function ReportOutdatedInfo({ storeName }: Props): ReactElement {
  const feedbackSubject = encodeURIComponent(
    `Feedback on store info: ${storeName}`
  )

  const link = `${
    email.mailToUrl
  }?subject=${feedbackSubject}&body=%0D%0A%0D%0A${
    isBrowser ? window?.location?.href : ""
  }`

  return (
    <ContainerDiv>
      Information not up to date? • <LinkA href={link}>Let us know</LinkA>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  font-size: 13px;
  color: #888888;
`

const LinkA = styled.a`
  font-weight: 700;
`
