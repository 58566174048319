import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import * as paths from "../utils/paths"
import * as styleConstants from "../styles/styleConstants"

type Props = {
  tag: string
}

export default function Tag({ tag = "" }: Props) {
  return (
    <LinkA to={paths.categoryPath(tag)} onTouchStart={() => true}>
      <ListItemLi>{tag}</ListItemLi>
    </LinkA>
  )
}

const LinkA = styled(Link)`
  display: block;
  margin: 4px 8px 4px 0;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  :last-child {
    margin-right: 0;
  }
`

const ListItemLi = styled.li`
  padding: 7px 13px;
  height: 31px;
  border-radius: 16px;
  font-size: ${styleConstants.bodyTextScreenSize};
  background: rgba(207, 207, 207, 0.33);
  color: black;
  transition: background 100ms;

  @media (hover: hover) {
    &:hover {
      background: rgba(155, 155, 155, 0.25);
    }
  }

  &:active {
    background: rgba(155, 155, 155, 0.35);
  }
`
