import { Address } from "../gatsby-node/data/dataSchemaV1"

export default function buildNYAddressString(addressObject?: Address): string {
  if (!addressObject) {
    return ""
  }
  const addressParts = []
  if (addressObject.street) {
    addressParts.push(addressObject.street.trim())
  }
  if (addressObject.neighborhood) {
    addressParts.push(addressObject.neighborhood.trim())
  }
  if (addressObject.borough) {
    addressParts.push(addressObject.borough.trim())
  }
  if (addressObject.city != null && addressObject.city !== "New York") {
    addressParts.push(addressObject.city.trim())
    if (addressObject.state) {
      addressParts.push(addressObject.state.trim())
    }
  }
  return addressParts.join(", ")
}
