import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import {
  primaryColor,
  contentPadding,
  hairLine,
  cssComputerOnly,
  cssMobileOnly,
} from "../styles/styleConstants"

import BackRegular from "src/images/ui_back_32.svg"
import BackBold from "src/images/ui_back_bold_32.svg"

type Props = {
  link: string
  text: string
}

export default function BackLink(props: Props) {
  return (
    <BackLinkElement to={props.link}>
      <BackRegularBlack />
      <BackBoldRed />
      <div>{props.text}</div>
    </BackLinkElement>
  )
}

const BackRegularBlack = styled(BackRegular)`
  ${cssComputerOnly} {
    display: none;
  }
`

const BackBoldRed = styled(BackBold)`
  ${cssMobileOnly} {
    display: none;
  }

  * {
    stroke: ${primaryColor};
  }
`

const BackLinkElement = styled(Link)`
  display: block;
  z-index: 100;
  user-select: none;

  ${cssMobileOnly} {
    position: absolute;
    left: 16px;
    top: -70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    & > div {
      display: none;
    }
  }

  ${cssComputerOnly} {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    background: white;
    width: 100%;
    height: 44px;
    padding: 0 ${contentPadding - 4}px;
    border-bottom: ${hairLine};
  }
`
