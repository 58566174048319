import React from "react"
import styled from "styled-components"
import titleCase from "titlecase"
import copy from "copy-to-clipboard"

import ContentContainer from "src/containers/ContentContainer"
import StandardContent from "../../components/StandardContent"
import BackLink from "../../components/BackLink"
import PageTitle from "../../components/PageTitle"
import HourSet from "../../components/HourSet"
import IconLink from "../../components/IconLink"
import TagGroup from "../../components/TagGroup"
import Footer from "../../components/Footer"

import buildNYAddressString from "src/utils/buildNYAddressString"
import googleSearchUrl from "src/utils/googleSearchUrl"
import displayUrl from "src/utils/displayUrl"
import { categoryPathForCategory, storePath } from "src/utils/paths"

import { Store as StoreType } from "src/gatsby-node/data/dataSchemaV1"
import SEO from "../../components/SEO"
import phoneNumberInUsFormat from "src/utils/phoneNumberInUsFormat"
import { contentMarginsCss } from "src/styles/styleConstants"
import ActionButtonsArea from "../../components/ActionButtonsArea"
import ActionButton from "../../components/ActionButton"

import NavigationIcon from "src/images/ui_navigation_32.svg"
import ShareIcon from "src/images/ui_share_32.svg"
import IconVideo from "src/images/ui_video_32.svg"
import IconWeb from "src/images/ui_web_32.svg"
import IconInstagram from "src/images/ui_instagram_32.svg"
import IconFacebook from "src/images/ui_facebook_32.svg"
import IconTwitter from "src/images/ui_twitter_32.svg"
import IconPhone from "src/images/ui_phone_32.svg"
import HorizontalRule from "../../components/HorizontalRule"
import Space from "../../components/Space"
import ReportOutdatedInfo from "../../components/ReportOutdatedInfo"
import { useConfirmation } from "../../components/ModalPortal"
import { Category } from "src/types"

export interface StoreProps {
  storeInfo: StoreType
  category?: Category
  implicitCategory?: string
}

export default function Store({
  storeInfo,
  category,
  implicitCategory,
}: StoreProps) {
  const showConfirmation = useConfirmation()

  const canonicalPath = storePath(storeInfo.name)
  const title = storeInfo.name
  const description = storeInfo.description
  const addressString = buildNYAddressString(storeInfo.address)
  const googleUrl = googleSearchUrl(storeInfo)

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={storeInfo.tags}
        canonicalPath={canonicalPath}
      />

      <ContentContainer>
        {/* This div is needed for sticky to work on Safari */}
        <div>
          {renderBack({ category, implicitCategory })}

          <TitleArea>
            {storeInfo.name && <PageTitle title={storeInfo.name} />}
            {storeInfo.address && <AddressDiv>{addressString}</AddressDiv>}
          </TitleArea>

          <StandardContent>
            {storeInfo.tags && <TagGroup tags={storeInfo.tags} />}
            <Description>{description}</Description>
            {storeInfo.hours && (
              <HourSet hours={storeInfo.hours} small={false} />
            )}

            <Space v={20} />

            <HorizontalRule />

            <ActionButtonsArea>
              <ActionButton
                Icon={NavigationIcon}
                label="Open in Maps"
                onClick={() => window.open(googleUrl, "_blank")}
              />
              <ActionButton
                Icon={ShareIcon}
                label="Share"
                onClick={() => {
                  const result = copy(window.location.href)
                  if (result === true) {
                    showConfirmation("Link Copied")
                  }
                }}
              />
            </ActionButtonsArea>

            <Space v={10} />

            {storeInfo.vintageVoyageur && (
              <IconLink
                linkText="Watch The Vintage Voyageur episode"
                linkUrl={storeInfo.vintageVoyageur}
                Icon={IconVideo}
              />
            )}
            {storeInfo.website && (
              <IconLink
                linkText={displayUrl(storeInfo.website)}
                linkUrl={storeInfo.website}
                Icon={IconWeb}
              />
            )}
            {storeInfo.instagram && (
              <IconLink
                linkText={storeInfo.instagram}
                linkUrl={`https://instagram.com/${storeInfo.instagram}`}
                Icon={IconInstagram}
              />
            )}
            {storeInfo.facebook && (
              <IconLink
                linkText={storeInfo.facebook}
                linkUrl={`https://facebook.com/${storeInfo.facebook}`}
                Icon={IconFacebook}
              />
            )}
            {storeInfo.twitter && (
              <IconLink
                linkText={storeInfo.twitter}
                linkUrl={`https://twitter.com/${storeInfo.twitter}`}
                Icon={IconTwitter}
              />
            )}
            {storeInfo.phone && (
              <IconLink
                linkText={phoneNumberInUsFormat(storeInfo.phone)}
                linkUrl={`tel:${storeInfo.phone}`}
                Icon={IconPhone}
              />
            )}

            <Space v={25} />

            <ReportOutdatedInfo storeName={storeInfo.name} />

            <Space v={40} />
          </StandardContent>
        </div>
      </ContentContainer>
      <Footer />
    </>
  )
}

function renderBack({ category, implicitCategory }) {
  if (category) {
    return (
      <BackLink link={categoryPathForCategory(category)} text="Back to List" />
    )
  } else if (implicitCategory) {
    const category = implicitCategory
    return (
      <BackLink
        link={categoryPathForCategory(category)}
        text={`Back to ${titleCase(category.name)}`}
      />
    )
  } else {
    return <BackLink link="/" text="Navigation" />
  }
}

const TitleArea = styled.div`
  ${contentMarginsCss}
  padding-top: 20px;
  padding-bottom: 20px;
`

const AddressDiv = styled.div`
  font-size: 15px;
  margin-top: 6px;
`

const Description = styled.div`
  font-size: 15px;
  line-height: 1.3;
  margin: 24px 0;
`

const LinksGroup = styled.div`
  margin: 24px 0;
  > * {
    margin: 8px 0;
  }
`
