import React from "react"
import styled from "styled-components"

import { contentPadding } from "../styles/styleConstants"

const StandardContent = styled.div`
  /* First fallback: If 'min' not supported */
  padding: 0 ${contentPadding}px;
  padding: 0 min(${contentPadding}px, 6vw);
`

export default StandardContent
