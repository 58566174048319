import { Hours } from "src/gatsby-node/data/dataSchemaV1"

const weekDayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

interface Time {
  hour: number
  minute: number
}

export function hourSetItemParts(
  hourSet: Hours,
  options: { ampm: boolean }
): {
  weekdays: string
  timeRange: string
  appointment?: string
} {
  const result = {
    weekdays: weekDaysString(hourSet.weekdays),
    timeRange: timeRangeString(
      { start: hourSet.start, end: hourSet.end },
      options.ampm
    ),
    appointment: hourSet.appointmentOnly ? "Appointment Only" : null,
  }
  return result
}

export function weekDaysString(weekdays?: Array<boolean>): string {
  if (weekdays == null) {
    return ""
  }
  const ranges = []
  let range: { start?: number; end?: number } = {}
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    if (range.start == null && weekdays[dayIndex] === true) {
      range.start = dayIndex
    }
    if (
      range.start != null &&
      (weekdays[dayIndex + 1] === false || weekdays[dayIndex + 1] == null)
    ) {
      range.end = dayIndex
    }
    if (range.start != null && range.end != null) {
      ranges.push({ ...range })
      range = {}
    }
  }
  const rangeStrings = ranges.map((range) => {
    if (range.start !== range.end) {
      return `${weekDayNames[range.start]}–${weekDayNames[range.end]}`
    } else {
      return weekDayNames[range.start]
    }
  })
  return rangeStrings.join(", ")
}

export function timeRangeString(
  times?: {
    start?: Time
    end?: Time
  },
  ampm: boolean = false
): string {
  if (times == null || times.start == null || times.end == null) {
    return ""
  }
  if (ampm) {
    let startHour = String(times.start.hour)
    let startAmPm = "am"
    let endHour = String(times.end.hour)
    let endAmPm = "pm"
    if (times.start.hour > 12) {
      startHour = `${times.start.hour - 12}`
    }
    if (times.start.hour > 11) {
      startAmPm = "pm"
    }
    if (times.end.hour > 12) {
      endHour = `${times.end.hour - 12}`
    }
    if (times.end.hour > 11) {
      endAmPm = "pm"
    }
    let startTime =
      startHour +
      (times.start.minute !== 0 ? ":" + padMinutes(times.start.minute) : "") +
      (startAmPm !== endAmPm ? startAmPm : "")
    if (times.start.hour === 12 && times.start.minute === 0) {
      startTime = "noon"
    }

    let endTime =
      endHour +
      (times.end.minute !== 0 ? ":" + padMinutes(times.end.minute) : "") +
      endAmPm
    if (times.end.hour === 24 && times.end.minute === 0) {
      endTime = "midnight"
    }

    return startTime + "–" + endTime
  } else {
    const timeStrings = [times.start, times.end].map((time) => {
      const minuteString = padMinutes(time.minute)
      const timeString = `${time.hour}:${minuteString}`
      return timeString
    })
    return `${timeStrings[0]}–${timeStrings[1]}`
  }
}

function padMinutes(minutes: number): string {
  if (minutes < 10) {
    return `0${minutes}`
  } else {
    return String(minutes)
  }
}
