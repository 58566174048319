import React, { ReactElement } from "react"
import styled from "styled-components"

interface Props {
  Icon: React.ComponentType
  label: string
  onClick: () => void
}

export default function ActionButton({
  Icon,
  label,
  onClick,
}: Props): ReactElement {
  return (
    <ContainerDiv onClick={onClick} onTouchStart={() => true}>
      <CircleDiv>
        <Icon />
      </CircleDiv>
      <LabelDiv>{label}</LabelDiv>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
`

const CircleDiv = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(207, 207, 207, 0.33);
  transition: background 100ms;

  @media (hover: hover) {
    ${ContainerDiv}:hover & {
      background: rgba(155, 155, 155, 0.25);
    }
  }

  ${ContainerDiv}:active & {
    background: rgba(155, 155, 155, 0.35);
  }
`

const LabelDiv = styled.div`
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-top: 4px;
  transition: opacity 100ms;

  @media (hover: hover) {
    ${ContainerDiv}:hover & {
      opacity: 0.8;
    }
  }
`
