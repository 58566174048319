import React from "react"
import styled from "styled-components"

import {
  cssComputerOnly,
  cssMobileOnly,
  computerContentWidth,
  backgroundStyle,
  actionButtonHeight,
} from "../styles/styleConstants"

type Props = {
  startY?: number
  children: any
}

/*

The content area consists of
- TopPaddingArea
  - Only mobile: Space for map to show through
  - startY varies based on route
- ScrollArea
  - Actual content
  - Scrolls on desktop

*/

export default (props: Props) => {
  return (
    <VerticalAreaSplit>
      <TopPaddingArea startY={props.startY} />
      <Shadow />
      <ContentBackground>
        <ScrollArea>{props.children}</ScrollArea>
      </ContentBackground>
    </VerticalAreaSplit>
  )
}

const VerticalAreaSplit = styled.div`
  position: relative;
  z-index: 100;
  pointer-events: none;

  ${cssComputerOnly} {
    position: absolute;
    left: 0;
    top: 0;
    width: ${computerContentWidth};
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const TopPaddingArea = styled.div<{ startY?: number }>`
  ${cssMobileOnly} {
    height: ${(p) => (p.startY ? `${p.startY}px` : "50vh")};
  }
`

const ContentBackground = styled.div`
  width: 100%;
  height: 100%;
  ${backgroundStyle}
`

const ScrollArea = styled.div`
  position: relative;
  pointer-events: auto;

  ${cssComputerOnly} {
    overflow-y: auto;
    height: 100%;
  }
`

// Optimized shadow using gradients

const Shadow = styled.div`
  position: absolute;
  pointer-events: none;
  user-select: none;

  ${cssMobileOnly} {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 20%,
      rgba(0, 0, 0, 0.08) 100%
    );
    margin-top: -40px;
    left: 0;
    height: 40px;
    width: 100%;
  }

  ${cssComputerOnly} {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.01) 20%,
      rgba(0, 0, 0, 0.08) 100%
    );
    top: 0;
    right: -40px;
    bottom: ${actionButtonHeight};
    width: 40px;
  }
`
