import { connect } from "react-redux"

import Store from "./Store"

const mapStateToProps = (state, { pageContext }) => ({
  implicitCategory: state.stores.implicitCategory,
  ...pageContext,
})

export default connect(mapStateToProps, null)(Store)
